import type { TabsProps } from "antd";
import { Layout, Row, Tabs, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import CreatorPaymentHistoryPaymentTabComponent from "../../../components/CreatorPaymentHisotryPaymentTabComponent";
import CreatorPaymentHistoryMonthlyReportTabComponent from "../../../components/CreatorPaymentHistoryMonthlyReportTabComponent";
import FooterComponent from "../../../components/FooterComponent";
import { FOOTER_IDS } from "../../../helpers/constant";
import { IPayoutRequest } from "../../../helpers/types";
import { payoutRequestService } from "../../../services/payoutRequest.service";
import "./index.less";
import useInfiniteScroll from "react-infinite-scroll-hook";

const CreatorPaymentHistoryPage = () => {
  const limit = 20;
  const { t } = useTranslation();
  const [payoutRequests, setPayoutRequests] = useState<IPayoutRequest[]>([]);
  const [totalRevenue, setTotalRevenuel] = useState<number>(0);
  const [reports, setReports] = useState<any>([]);
  const [offset, setOffSet] = useState<any>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadMoreData();
  }, []);

  const loadMoreData = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await payoutRequestService.getPayoutHistory( {limit , offset });
      if (response.status === 0) {
        let data = [...payoutRequests , ...response.data.payment]
        setLoading(false)
        setPayoutRequests(data);
        setTotalRevenuel(response.data.totalRevenue);
        setReports(response.data.monthReport);
        setOffSet(data.length);
        if(data.length >= response.data.totalPayments){
          setHasMore(false)
          setLoading(false)
        }
      }
    } catch (error) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMoreData,
  });

  const tabs: TabsProps["items"] = [
    {
      key: "payment",
      label: t("versement"),
      children: <CreatorPaymentHistoryPaymentTabComponent list={payoutRequests} sentryRef={sentryRef} hasMore={hasMore} loading={loading} />,
    },
    {
      key: "monthlyReport",
      label: t("monthly-reports"),
      children: <CreatorPaymentHistoryMonthlyReportTabComponent totalRevenue={totalRevenue} reports={reports} />,
    },
  ];

  return (
    <Layout>
      <Layout.Content className="content creatorPaymentHistoryContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container">
          <ArrowLeftIconComponent left={0} top={2} />
          <Typography.Text className="header-title-black-20">{t("payments-history")}</Typography.Text>
        </Row>
        <div className="tabs-container">
          <Tabs className="custom-tabs hideTab background-white" items={tabs} defaultActiveKey="payment" centered />
        </div>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default CreatorPaymentHistoryPage;
