import React from "react";
import { Space, Row, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";
import { IPayoutRequest } from "../../helpers/types";
import dayjs from "dayjs";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";

type props = {
  list?: IPayoutRequest[];
  sentryRef : any;
  loading : boolean; 
  hasMore : boolean;
};

const CreatorPaymentHistoryPaymentTabComponent: React.FC<props> = ({ list , sentryRef , loading , hasMore}) => {
  const { t } = useTranslation();

  return (
    <Space className="paymentTab w-full g-15" direction="vertical">
      {list && list.map((item, index) => {
        return (
          <Row className="justify-content-between items-center" key={index}>
            <Space>
              <Tag className="tag">{item.refId}</Tag>
              <Typography.Text className="font-12-regular text-grey-color">
                {t("completed-on")} {dayjs(item.createdAt).format("DD MMMM YYYY")}
              </Typography.Text>
            </Space>
            <Typography.Text className="font-13-bold text-white-color">
              {item.netprice?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} €
            </Typography.Text>
          </Row>
        );
      })}
      {(loading || hasMore) && <SpinnerComponent refProp={sentryRef} />}
    </Space>
  );
};

export default CreatorPaymentHistoryPaymentTabComponent;
