import type { UploadFile } from "antd";
import { Button, Layout, Row, Space, Spin, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AddCircleGrey from "../../../assets/icons/add-circle-grey.svg";
import AddMediaComponent from "../../../components/AddMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { storageService } from "../../../services";
import { quickFileService } from "../../../services/quickFile.service";
import "./index.less";

const CreatorAddMediaComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { albumId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);

  const handleFilesChange = (fileList: UploadFile<[]>[]) => {
    setFileList(fileList);
  };

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  async function handleMediaUpload() {
    setLoading(true);
    try {
      let formData = new FormData();
      const videoFileToChunk: any[] = [];

      fileList.forEach((file: UploadFile) => {
        if (file.status !== "removed") {
          if (file?.originFileObj?.type?.includes("image/")) {
            formData.append("quickFiles", file.originFileObj as any);
          } else {
            videoFileToChunk.push(file);
          }
        }
      });

      if (videoFileToChunk.length > 0) {
        const videoFiles: any[] = [];
        const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
        setTotalSize(totalSizes);

        for (let i = 0; i < videoFileToChunk.length; i++) {
          const file = videoFileToChunk[i];
          try {
            const result = await storageService.uploadFileMultiPart(file, setLoadedData, totalSizes, "quickFiles");

            videoFiles.push({
              Location: result.Location,
              isPrivate: file.isPrivate,
              Key: result.Key,
              ETag: result.ETag,
              Bucket: result.Bucket,
              filename: file.name,
              size: file.size,
            });
          } catch (error: any) {
            // Show error message for individual file upload failure
            message.error(t(error.message));
            setLoading(false);
            return; // Exit the function entirely
          }
        }

        if (videoFiles.length > 0) {
          formData.append("videoFiles", JSON.stringify(videoFiles));
        }
      }

      // Upload the media files to the album
      await quickFileService.addMediaToAlbum(albumId, formData, setProgress);
      message.info(t("big-file-info"), 6);
      navigate("/creator/AlbumFeeds/" + albumId, { replace: true });
    } catch (error: any) {
      message.error(error.message || "Failed to upload media to album");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Spin
      spinning={loading}
      style={{ maxHeight: "100%" }}
      indicator={<SpinnerComponent progress={true} progressValue={progress} message={t("stay-on-page-during-uploading") as string} />}
    >
      <Layout className="gradient-background-blue-settings">
        <Layout.Content className="content CreatorAddMediaContentWrapper background-transparent">
          <Row className="relative justify-content-center header-title-container-70">
            <ArrowLeftIconComponent top={10} left={15} />
            <Typography.Text className="header-title-black-35">{t("media")}</Typography.Text>
          </Row>
          <Space direction="vertical" className="items-center w-full mt-30 addMediaAlbumWrapper">
            <AddMediaComponent disabled={loading} title=" " crop={false} onFilesChange={handleFilesChange}>
              <div>
                <img src={AddCircleGrey} width={250} alt="plus" />
              </div>
            </AddMediaComponent>
          </Space>
          <div className="full-width-container">
            <div className="centered-content">
              <Typography.Text className="creatorAddMediaText mt-5">{t("click-add-media-to-your-album")}</Typography.Text>
            </div>
          </div>

          <Space direction="vertical" className="items-center w-full mt-79 mb-20">
            <Button
              disabled={loading}
              className={`AlbumMediaUploadButton ${fileList.length > 0 ? "bg-white" : "bg-grey"}`}
              onClick={() => {
                fileList.length > 0 && !loading && handleMediaUpload();
              }}
            >
              {t("auth-forgot-password-terminate-label")}
            </Button>
          </Space>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default CreatorAddMediaComponent;
