import { Button, Col, Layout, Row, Space, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../../assets/icons/arrow-right-white.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FanSubscriptionPageModel from "../../../components/FanSubscriptionPageModel/FanSubscriptionPageModel";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { IAllSubscription } from "../../../helpers/types";
import { subscriptionsService } from "../../../services";
import "./index.less";

const FanSubscriptionPage = () => {
  const { t } = useTranslation();
  const [fanSubscriptionModel, setFanSubscriptionModel] = useState(false);
  const [modifySubscriptionPlan, setModifySubscriptionPlan] = useState<IAllSubscription>();
  const [subscriptionData, setSubscriptionData] = useState<IAllSubscription[]>([]);
  const [skip, setSkip] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  const [hasNextPage, setHasNextPage] = useState<any>(false);
  const [totalData, setTotalData] = useState<any>(0);
  const navigate = useNavigate();
  const NB_USER_TO_SHOW = 10;

  async function subscriptions(NB_USER_TO_SHOW: any, skip: any) {
    setLoading(true);
    await subscriptionsService
      .allSubscriptions(NB_USER_TO_SHOW, skip)
      .then((response: any) => {
        setLoading(false);
        setTotalData(response?.total);
        if (subscriptionData.length === 0) {
          setSubscriptionData(response?.data);
        } else {
          setSubscriptionData((prevData) => Array.from(new Set([...prevData, ...(response?.data ?? [])])));
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(t("something-want-wrong"));
      });
  }

  async function loadMoreUsers() {
    const nextPage = skip + NB_USER_TO_SHOW;
    setSkip((prev: any) => prev + NB_USER_TO_SHOW);
    const totalPages = Math.ceil(totalData! / NB_USER_TO_SHOW);
    await subscriptions(NB_USER_TO_SHOW, nextPage);
    if (nextPage >= totalPages) {
      setHasNextPage(false);
    }
  }

  useEffect(() => {
    subscriptions(NB_USER_TO_SHOW, 0);
    setSubscriptionData([]);
  }, []);

  const unSubscribe = async (creatorId: string) => {
    subscriptionsService
      .unsubscribeCreator(creatorId)
      .then((res) => {
        message.success(t("unsubscribe-message"));
        setSkip(0);
        setSubscriptionData([]);
        setHasNextPage(false);
        setLoading(false);
        setTotalData(0);
        subscriptions(NB_USER_TO_SHOW, 0);
        setModifySubscriptionPlan(undefined);
      })
      .catch((error) => {
        message.error(t("something-want-wrong"));
      });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: loadMoreUsers,
  });

  return (
    <Layout className="gradient-background-subs">
      <Layout.Content className="content fanSubscriptionPageContentWrapper background-transparent">
        <Row className="relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent top={12} />
          <Typography.Text className="header-title-black-35">{t("my-creators")}</Typography.Text>
        </Row>
        {subscriptionData?.length === 0 && !loading && (
          <Typography.Text className="noSubscriptionsMessage">{t("you-are-not-subscribed-to-any-creator")}</Typography.Text>
        )}
        {subscriptionData?.map((item, index) => {
          return (
            <Space key={index} className="w-full mt-13 subs-container">
              <Row className="w-full items-center">
                <img className="fanSubscriptionPageAvatarImg" src={item?.creatorInfo?.avatar} alt="img" />
                <Col className="detailsWrapper">
                  <Typography.Text className="fanSubscriptionStatus">
                    {t(`renewal-label`)} {item?.status === "active" ? t("active-label-2") : t("disabled-label-2")}
                  </Typography.Text>
                  <Row
                    className="items-center"
                    onClick={() => {
                      navigate(`/p/${item?.creatorInfo?.username}`);
                    }}
                  >
                    <Typography.Text className="fanSubscriptionCreatorName mr-5">{item?.creatorInfo?.name}</Typography.Text>
                  </Row>
                  <Row>
                    <Button className={`fanSubscriptionAssetButton mt-5 ${item?.status === "active" ? "" : "opacity-7"}`}>
                      {item?.status === "active" ? t(`active-label-to`) : t(`inactive-lavel-to`)}
                    </Button>
                  </Row>
                </Col>
                <div className="chevron-right">
                  <img
                    alt="arrow right"
                    width={15}
                    src={ArrowRight}
                    onClick={() => {
                      setFanSubscriptionModel(true);
                      setModifySubscriptionPlan(item);
                    }}
                  />
                </div>
              </Row>
            </Space>
          );
        })}
        {modifySubscriptionPlan ? (
          <FanSubscriptionPageModel
            fanSubscriptionModel={fanSubscriptionModel}
            modifySubscriptionPlan={modifySubscriptionPlan}
            setFanSubscriptionModel={setFanSubscriptionModel}
            unSubscribe={unSubscribe}
            isCreator={true}
            title={modifySubscriptionPlan.creatorInfo.name}
          />
        ) : null}
        {totalData !== subscriptionData?.length && totalData !== 0 && subscriptionData?.length !== 0 && !loading && (
          <Row className={`mx-auto bg-black`}>
            <Typography.Text
              onClick={() => {
                // setSeeAllResults(true);
                setHasNextPage(true);
              }}
              className="SearchSeeAllResults mx-auto"
            >
              {t(`see-all-results`)}
            </Typography.Text>
          </Row>
        )}
        {(loading || hasNextPage) && <SpinnerComponent refProp={sentryRef} />}
      </Layout.Content>
    </Layout>
  );
};

export default FanSubscriptionPage;
